<template>
  <div
    class="container"
    :style="{
      justifyContent: inPay ? 'space-between' : 'center',
    }"
  >
    <template v-if="inPay">
      <p class="name">付款给{{ info.hotelName }}</p>
      <p class="info">
        <span class="tips">你需支付</span>
        <br />
        <span class="money">
          <span v-if="info.price" class="symbol">￥</span>
          {{ fixed(info.price) }}
        </span>
      </p>
      <span class="pay" @click="pay">支 付</span>
    </template>
    <span v-else>请使用微信或支付宝扫码</span>
    <div v-if="loading" class="modal" @click.stop="">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, inject, onMounted } from "vue";

const api = "https://sst.api.webinn.online/v1.2/";

const toast = inject("$toast");
const axios = inject("axios");

const inPay = ref(-1);
const isWechat = ref(false);
const info = ref({});
const loading = ref(false);
const authCode = ref("");
const data = reactive({});
const query = window.location.search.substring(1);
const vars = query.split("&");
for (let i = 0; i < vars.length; i++) {
  const pair = vars[i].split("=");
  data[pair[0]] = pair[1];
}

onMounted(async () => {
  isWechat.value = /MicroMessenger/.test(window.navigator.userAgent);
  if (isWechat.value || /AlipayClient/.test(window.navigator.userAgent)) {
    if (isWechat.value && !data.code) {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa2b9b0e8f700f867&redirect_uri=${window.location.href}&response_type=code&scope=snsapi_base`;
      return;
    }
    inPay.value = 1;
    loading.value = true;
    try {
      info.value = await axios.get(`${api}wxApplet/getPayData`, {
        params: {
          payId: data.payId,
        },
      });
    } catch (e) {
      toast(e);
    } finally {
      loading.value = false;
    }
    if (isWechat.value) {
      try {
        const { appid, timestamp, noncestr, signature } = await axios.get(
          `${api}wxApplet/getWxConfig`,
          {
            params: {
              url: window.location.href.split("#")[0],
            },
          }
        );
        window.wx.config({
          debug: false,
          appId: appid,
          timestamp,
          nonceStr: noncestr,
          signature,
          jsApiList: ["hideAllNonBaseMenuItem", "chooseWXPay"],
        });
        window.wx.ready(async () => {
          window.wx.hideAllNonBaseMenuItem();
          pay();
        });
      } catch (e) {
        toast(e);
      }
    } else {
      window.ap.getAuthCode(
        {
          appId: "2018080160818924",
          // scopes: ["auth_user"],
        },
        function (res) {
          authCode.value = res.authCode;
          pay();
        }
      );
    }
  } else {
    inPay.value = 0;
  }
});

async function pay() {
  loading.value = true;
  try {
    const d = await axios.get(`${api}wxApplet/getPayInfo`, {
      params: {
        payId: data.payId,
        payWay: isWechat.value ? 1 : 2,
        code: isWechat.value ? data.code : authCode.value,
      },
    });
    if (isWechat.value) {
      const {
        timeStamp: timestamp,
        nonceStr,
        paySign: signature,
        package: package1,
        signType,
      } = d;
      window.wx.chooseWXPay({
        timestamp,
        nonceStr,
        package: package1,
        signType,
        paySign: signature,
        success: () => {
          toast("支付成功");
        },
        fail: () => {
          toast("支付失败");
        },
        cancel: () => {
          toast("支付失败");
        },
      });
    } else {
      window.ap.tradePay(
        {
          tradeNO: d.tradeNO,
        },
        function (res) {
          if (res.resultCode === "9000") {
            toast("支付成功");
          } else {
            toast("支付失败");
          }
        }
      );
    }
  } catch (e) {
    if (e === 201) {
      window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}?payId=${data.payId}`;
      return;
    }
    toast(e);
  } finally {
    loading.value = false;
  }
}

function fixed(num, fractionDigits = 2) {
  if (num === null || typeof num === "undefined") {
    return "";
  }
  let n = num;
  if (typeof num !== "number") {
    try {
      n = parseFloat(num);
      if (isNaN(n)) {
        return "";
      }
    } catch (e) {
      return "";
    }
  }
  const string = n.toString();
  const length = string.lastIndexOf(".");
  if (length < 0 || string.length - length - 1 < fractionDigits) {
    return n.toFixed(fractionDigits);
  } else {
    return string.substring(0, length + fractionDigits + 1);
  }
}
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
}

#app {
  text-align: center;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.name {
  margin-top: 100px;
  color: black;
  font-size: 1rem;
  font-weight: bold;
}

.info {
  text-align: start;
  border-bottom: #dddddd 1px solid;
  width: 80%;
  margin-bottom: 200px;
}

.tips {
  font-size: 12px;
}

.money {
  margin: 100px 0 0 0;
  color: #e62601;
  font-size: 2rem;
  font-weight: bold;
}

.symbol {
  font-size: 1rem;
}

.pay {
  margin-bottom: 100px;
  background: #07c160;
  border-radius: 5px;
  padding: 15px 100px;
  color: white;
  font-size: 0.8rem;
}

.modal {
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
}

.loader {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.5rem solid #f3f3f3;
  border-top: 0.5rem solid #037eff;
  animation: load 2s linear infinite;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
