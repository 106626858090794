import { createApp } from "vue";
import App from "./App.vue";
import DKToast from "vue-dk-toast";
import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = "/api/";
axios.interceptors.response.use(
  (response) => {
    if (response.data.code === undefined) {
      return Promise.resolve(response.data);
    }
    if (response.data.code === 200) {
      return Promise.resolve(response.data.data);
    } else {
      return Promise.reject(
        response.data.code === 201 ? 201 : response.data.msg
      );
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const app = createApp(App);
app.use(DKToast, {
  disableClick: true,
  styles: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
  },
});
app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.mount("#app");
